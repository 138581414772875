import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import StepsComponent from "../components/ProgramAfiliere/StepsComponent"
import "/src/assets/css/ProgramAfiliere/program-afiliere.css"
import InfoBlue from "../components/ProgramAfiliere/InfoBlue"
import HeroSection from "../components/ProgramAfiliere/HeroSection"
import SEO from "../components/App/SEO"
import InfoBlocks from "../components/Index/InfoBlocks"

const Affiliates = () => {
  return (
    <Layout>
      <SEO title="Hosting Affiliate - Sitebunker.net - Efficient hosting and performant security"
          description="Sign up for the SiteBunker Hosting Affiliate Program and enjoy your extra earnings from any recurring subscription you sell."/>
      <Navbar />
      <HeroSection />
      <InfoBlue />
      <StepsComponent />
      <div className="container">
        <h3 className="text-center">Here is why you should recommend SiteBunker</h3>
      </div>
      <InfoBlocks/>
      <Footer />
    </Layout>
  )
}
export default Affiliates

import React from "react"
import { Link } from "gatsby"


const InfoBlue = () => {
  return (

    <div id="affiliate-info" className="main-blue-bg  text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-12 mx-auto">
            <h3 className=" text-white mb-5 font-weight-normal text-fix-h2">We guarantee you <strong>15%
              COMMISSION</strong> for every recurring payment made by your clients.
            </h3>
            <h4 className=" text-white mb-5 font-weight-normal text-fix-h4">
              Every sale you make as an affiliate will bring a recurrent bonus to you. This program is available for
              every web hosting subscription and the payment is done monthly or annually. Each payment made by your
              clients guarantees you a 15% Commission.
            </h4>
            <h4 className="text-white">Apply NOW to SiteBunker’s hosting affiliate program.</h4>
            <h4 className="text-white font-weight-normal mb-5">Get in touch with our customer support team and find out more details.</h4>
            <Link to="/contact/" className="form-btn  d-inline-block mb-5 ">Contact us</Link>
            <h4 className=" text-white font-weight-normal">15% COMMISSION* - How to apply for SiteBunker Hosting Affiliate Program</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBlue

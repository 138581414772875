import React from "react"
import ProgramAfiliere from "../../assets/images/new-imgs/program-afiliere.png"

const HeroSection = () => {
  return (
    <div className="page-title-area affiliate-program">
      <div className="container">
        <div className="page-title-content text-left">
          <img src={ProgramAfiliere} className='svgafiliere' alt="Web Hosting" loading="lazy"/>
          <div className="row">
            <div className="col-md-7 col-12">
              <h2 className="mb-4 responsive">SiteBunker Hosting Affiliate Program</h2>
              <p className="font-weight-normal">Sign up for the SiteBunker Hosting Affiliate Program and enjoy your
                extra earnings from any recurring subscription you sell</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection

import React from "react"

import nrOne from "/src/assets/images/program-afiliere/nr-1.png"
import nrTwo from "/src/assets/images/program-afiliere/nr-2.png"
import nrThree from "/src/assets/images/program-afiliere/nr-3.png"

const StepsComponent = () => {
  return (

    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="steps mb-4">
            <div className="row align-items-center">
              <div className="col-lg-2 col-3">
                <img src={nrOne} alt="Number One" loading="lazy" />
              </div>
              <div className="col-lg-10 col-9">
                <p className="step-name">REGISTER</p>
                <span className="step-info">Contact us to get your hosting affiliate link</span>
              </div>
            </div>
          </div>

          <div className="steps mb-4">
            <div className="row align-items-center">
              <div className="col-lg-2 col-3">
                <img src={nrTwo} alt="Number One" loading="lazy" />
              </div>
              <div className="col-lg-10 col-9">
                <p className="step-name">Inserts the HTML code</p>
                <span className="step-info">Start making money by selling SiteBunker’s hosting subscriptions</span>
              </div>
            </div>
          </div>

          <div className="steps mb-4">
            <div className="row align-items-center">
              <div className="col-lg-2 col-3">
                <img src={nrThree} alt="Number One" loading="lazy" />
              </div>
              <div className="col-lg-10 col-9">
                <p className="step-name">Choose your banner</p>
                <span
                  className="step-info">Get the HTML code directly from your account and select your favorite banner</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <p className="mb-4"><strong>How do I get the SiteBunker Hosting Affiliate commission?</strong></p>
          <p className="mb-4">You will be able to check constantly your hosting affiliate earning directly into your
            account. You can use the earned amount to buy or pay any SiteBunker subscription, but you can also choose to
            cash out the entire amount**. The minimum amount you can use or withdraw is 10 euros.</p>
          <p className="mb-0 small">* Domain name acquisitions, vps servers and reseller hosting are excluded.</p>
          <p className="mb-0 small">** Cash Withdrawal is possible only by invoice payment. This means that you need to
            be able to invoice SiteBunker.
          </p>
        </div>
      </div>
    </div>

  )
}

export default StepsComponent
